import { StoreSigning } from 'types/Store';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface StoreFinderState {
  activeSigning?: StoreSigning;
  selectedFacetCodes?: string[];
}

export interface StoreFinderStore extends StoreFinderState {
  actions: {
    setActiveSigning: (signing?: StoreSigning) => void;
    toggleSelectedFacetCodes: (facetCode: string) => void;
  };
}

const initialState: StoreFinderState = {
  activeSigning: undefined,
  selectedFacetCodes: undefined,
};

export const useStoreFinderStore = create<StoreFinderStore>()(
  devtools(
    (set, get) => ({
      ...initialState,
      actions: {
        setActiveSigning: (signing) => set(() => ({ activeSigning: signing })),
        toggleSelectedFacetCodes: (facetCode) => {
          const facets = [...(get().selectedFacetCodes ?? [])];

          if (facets.includes(facetCode)) {
            const existingSigningCodeIndex = facets.findIndex((signing) => signing === facetCode);
            facets.splice(existingSigningCodeIndex, existingSigningCodeIndex + 1);
          } else {
            facets.push(facetCode);
          }

          set(() => ({ selectedFacetCodes: facets }));
        },
      },
    }),
    { name: 'storeFinderStore' },
  ),
);

export const useSelectedFacetCodes = () => useStoreFinderStore((state) => state.selectedFacetCodes);
export const useActiveSigning = () => useStoreFinderStore((state) => state.activeSigning);
export const useStoreFinderActions = () => useStoreFinderStore((state) => state.actions);
