import Button from 'components/Button/Button';
import Modal from 'components/Layout/Modal/Modal';
import Link from 'components/Link/Link';
import Typography from 'components/Typography/Typography';
import { useIntl } from 'react-intl';
import { useActiveSigning, useStoreFinderActions } from 'store/store';

const SigningModal = () => {
  const { formatMessage } = useIntl();

  const activeSigning = useActiveSigning();
  const { setActiveSigning } = useStoreFinderActions();

  const handleClose = () => setActiveSigning(undefined);

  if (!activeSigning) return null;

  return (
    <Modal closeModal={handleClose} show title={activeSigning.title}>
      <Typography html={activeSigning.description} />

      {activeSigning?.link && (
        <Link newTab url={activeSigning.link}>
          <Button color="secondary" onClick={handleClose}>
            {formatMessage({ id: 'store_finder_signing_more_info_btn' })}
          </Button>
        </Link>
      )}
    </Modal>
  );
};

export default SigningModal;
